import type {
  ISettingsFont,
  IStyleParam,
  StyleParamType,
} from '@wix/tpa-settings';

import { wixFontParam } from '@wix/tpa-settings';

type DefaultValueContext = Parameters<
  NonNullable<
    IStyleParam<StyleParamType.Font, ISettingsFont>['getDefaultValue']
  >
>[0];

type SiteTextPreset = Parameters<typeof wixFontParam>[0];

type GetCascadingFontDefaultValueOptions = {
  context: DefaultValueContext;
  fontStyleParam: IStyleParam<StyleParamType.Font, ISettingsFont>;
  mobileFontSizeStyleParam: IStyleParam<StyleParamType.Number, number>;
  defaults: {
    preset: Parameters<typeof wixFontParam>[0];
    desktopFontSize: number;
    mobileFontSize: number;
    style?: NonNullable<Parameters<typeof wixFontParam>[1]>['style'];
  };
};

const getDefaultFont = (
  name: SiteTextPreset,
  fontDefaults: Partial<ISettingsFont>,
  context: DefaultValueContext,
) => {
  const { value: _, ...fontWithoutValue } = wixFontParam(
    name,
    fontDefaults,
  )(context);

  return fontWithoutValue as ISettingsFont;
};

export const getCascadingFontDefaultValue = ({
  context,
  fontStyleParam,
  mobileFontSizeStyleParam,
  defaults,
}: GetCascadingFontDefaultValueOptions): ISettingsFont => {
  const font = context.getStyleParamValue(fontStyleParam);

  if (font) {
    const { value: _value, preset: _preset, ...fontDefaults } = font;
    const mobileFontSize = context.getStyleParamValue(mobileFontSizeStyleParam);

    return context.isMobile
      ? getDefaultFont(
          defaults.preset,
          { ...fontDefaults, size: mobileFontSize ?? defaults.mobileFontSize },
          context,
        )
      : font;
  }

  return getDefaultFont(
    defaults.preset,
    {
      size: context.isMobile
        ? defaults.mobileFontSize
        : defaults.desktopFontSize,
      style: defaults.style ? defaults.style : undefined,
    },
    context,
  );
};
